export const headerMenu = () => {
  const headerMenu = document.querySelectorAll('.js-headerMenu');
  headerMenu.forEach((menu) => {

    menu.addEventListener('mouseover', () => {
      if (window.innerWidth > 768) {
        menu.dataset.menu = "open";
      }
    });

    menu.addEventListener('mouseout', () => {
      if (window.innerWidth > 768) {
      menu.dataset.menu = "close";
      }
    });
  });

  const hambButton = document.querySelector('.js-hambButton');
  const body = document.querySelector('body');

  hambButton.addEventListener('click', () => {
    body.dataset.menuState = body.dataset.menuState === "open" ? "close" : "open";
  });

  window.addEventListener('resize', () => {
    if (window.innerWidth > 768) {
      body.dataset.menuState = "close";
    }
  });
};